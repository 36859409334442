import React, { useState, useEffect } from 'react'
import { Search } from '@truphone/icons'
import CoverageList from './CoverageList'
import MapView from './MapView'
import './coverage.css'
import Select from './Select'
import MultiSelect from './MultiSelect'
import { InlineDefsInline } from './Icons'

const fromQueryStringOrNull = (key) => {
  const urlSearchParams = new URLSearchParams(window.location.search)
  return urlSearchParams.get(key) || undefined
}

export default function CoveragePage(props) {
  const [searchTerm, setSearchTerm] = useState('')
  const [mapView, setMapView] = useState(false)
  const [countries, setCountries] = useState([])
  const [plans, setPlans] = useState([])
  const [addons, setAddons] = useState([])
  const [features, setFeatures] = useState([])
  const [filter, setFilter] = useState([])

  useEffect(() => {
    setPlans([
      { text: props.planFilterPlaceholder, value: undefined },
      ...props.coverageData
        .map((x) => x.plans)
        .flat()
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((x) => ({ text: x, value: x }))
    ])

    setAddons([
      { text: props.addonFilterPlaceholder, value: undefined },
      ...props.coverageData
        .map((x) => x.region)
        .filter((value, index, list) => value && list.indexOf(value) === index)
        .sort((a, b) => a.localeCompare(b))
        .map((x) => ({ text: x, value: x }))
    ])

    setFeatures([
      { text: props.featureFilterPlaceholder },
      ...props.coverageData
        .map((x) => x.features)
        .flat()
        .filter((x, i, list) => list.indexOf(x) == i)
        .sort()
        .map((x) => {
          if (x === '2G' || x === '3G' || x === '4G' || x === '5G' || x === 'LTE-M') {
            return { text: x, value: x }
          }

          if (x === 'local-number') {
            return { text: props.localNumberText, value: x }
          }

          if (x === 'wifi-calling') {
            return { text: props.wifiCallingText, value: x }
          }

          if (x === 'volte') {
            return { text: props.volteText, value: x }
          }
        })
        .filter((x) => !!x)
    ])

    const filter = {
      plan: fromQueryStringOrNull('plan'),
      addon: fromQueryStringOrNull('addon'),
      feature: fromQueryStringOrNull('feature')
    }

    setFilter(filter)

    document.documentElement.style.setProperty('--color-primary', props.iconColor)
  }, [])

  useEffect(() => {
    let countries = props.coverageData

    const urlSearchParams = new URLSearchParams(window.location.search)
    urlSearchParams.delete('feature')
    urlSearchParams.delete('addon')
    urlSearchParams.delete('plan')
    let filterCountries = countries
    if (filter.feature) {
      filter.feature.split(',').forEach((filter) => {
        if (filter === 'local-number') {
          filterCountries = filterCountries.filter((x) => x.localNumber)
        } else if (filter === 'wifi-calling') {
          filterCountries = filterCountries.filter((x) => x.wifiCalling)
        } else if (filter.toLowerCase() === 'volte') {
          filterCountries = countries.filter((x) => x.voLte)
        } else {
          filterCountries = filterCountries.filter((x) => x.features && x.features.includes(filter))
        }
      })

      urlSearchParams.set('feature', filter.feature)
    }

    if (filter.addon) {
      filterCountries = filterCountries.filter((x) => x.region === filter.addon)
      urlSearchParams.set('addon', filter.addon)
    }

    if (filter.plan) {
      filterCountries = filterCountries.filter((x) => x.plans && x.plans.includes(filter.plan))
      urlSearchParams.set('plan', filter.plan)
    }

    filterCountries = filterCountries.sort((a, b) => a.name.localeCompare(b.name))
    setCountries(filterCountries)

    const location = new URL(window.location)
    location.search = urlSearchParams.toString()
    if (location.toString() !== window.location.toString()) {
      window.history.replaceState(filter, document.title, location)
    }
  }, [filter])

  function search(items) {
    const searchProperties = ['name']

    return items.filter((item) => {
      return searchProperties.some((propertyName) => {
        return item[propertyName].toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
      })
    })
  }

  return (
    <>
      <InlineDefsInline />
      <div className="bg-gray-100">
        <div className="h-28 w-full"></div>
        <div className="py-12 container mx-auto px-10">
          {props.title && <h2 className="h3-light px-6 lg:px-0 mb-8 text-center">{props.title}</h2>}

          <form autoComplete="off">
            <div className="mb-4 flex flex-wrap lg:flex-nowrap justify-between w-full">
              <div className="relative w-full lg:w-3/5 my-2 md:pr-4">
                <input
                  type="search"
                  name="search"
                  placeholder={props.searchPlaceholder}
                  className="w-full border border-gray-200 rounded-lg p-3 pl-10 hover-color"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Search
                  className="w-5 absolute left-3 top-1/2 -translate-y-1/2"
                  style={{ color: props.iconColor }}
                />
              </div>

              <div className="flex items-center w-full md:w-1/3  my-2 md:pr-4">
                <Select
                  options={plans}
                  className="border bg-white border-gray-200 rounded-lg flex items-center h-full outline-color"
                  value={filter.plan}
                  onChange={(value) =>
                    setFilter((prevFilter) => {
                      const filter = { ...prevFilter }
                      filter.plan = value
                      filter.addon = undefined
                      return filter
                    })
                  }
                />
              </div>

              <div className="flex items-center w-full md:w-1/3  my-2 md:pr-4">
                <Select
                  options={addons}
                  className="border bg-white border-gray-200 rounded-lg flex items-center h-full outline-color"
                  value={filter.addon}
                  onChange={(value) =>
                    setFilter((prevFilter) => {
                      const filter = { ...prevFilter }
                      filter.plan = undefined
                      filter.addon = value
                      return filter
                    })
                  }
                />
              </div>

              <div className="flex items-center w-full md:w-1/3  my-2 md:pr-4 lg:max-w-xs">
                <MultiSelect
                  placeholder={props.featureFilterPlaceholder}
                  options={features}
                  onChange={(values) => {
                    setFilter((prevFilter) => {
                      const filter = { ...prevFilter }
                      filter.feature = values.join(',')
                      return filter
                    })
                  }}
                  value={filter.feature}
                  className="border bg-white border-gray-200 rounded-lg flex items-center h-full outline-color"
                />
              </div>
              <div className="flex justify-start lg:justify-between w-full mt-2 md:my-2 map-icons">
                <div
                  className={`${
                    mapView && 'opacity-50'
                  }   hover:opacity-70 cursor-pointer justify-center w-12 flex items-center mr-2`}
                  onClick={() => setMapView(false)}
                  style={{ color: !mapView && props.iconColor }}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      id="icon/24x24/textLight/list"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <line
                        x1="7"
                        y1="5"
                        x2="21"
                        y2="5"
                        id="Path"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                      <path
                        d="M4,20 C4.55228475,20 5,19.5522847 5,19 C5,18.4477153 4.55228475,18 4,18 C3.44771525,18 3,18.4477153 3,19 C3,19.5522847 3.44771525,20 4,20 Z"
                        id="Path"
                        fill="currentColor"
                      ></path>
                      <line
                        x1="7"
                        y1="12"
                        x2="21"
                        y2="12"
                        id="Path"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                      <path
                        d="M4,13 C4.55228475,13 5,12.5522847 5,12 C5,11.4477153 4.55228475,11 4,11 C3.44771525,11 3,11.4477153 3,12 C3,12.5522847 3.44771525,13 4,13 Z"
                        id="Path"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M4,6 C4.55228475,6 5,5.55228475 5,5 C5,4.44771525 4.55228475,4 4,4 C3.44771525,4 3,4.44771525 3,5 C3,5.55228475 3.44771525,6 4,6 Z"
                        id="Path"
                        fill="currentColor"
                      ></path>
                      <line
                        x1="7"
                        y1="19"
                        x2="21"
                        y2="19"
                        id="Path"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></line>
                    </g>
                  </svg>
                </div>
                <div
                  className={`${
                    !mapView && 'opacity-50'
                  }  hover:opacity-70 cursor-pointer w-12 flex items-center`}
                  onClick={() => setMapView(true)}
                  style={{ color: mapView && props.iconColor }}
                >
                  <svg
                    width="48"
                    height="48"
                    viewBox="0 0 48 48"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <path d="M14.37 33.876c.675-.12 1.365-.379 2.102-.614 1.1-.353 2.202-.752 3.218-1.009.252-.063.516-.059.768.002l6.69 1.622c1.12.245 2.231-.218 3.473-.615.766-.246 1.533-.514 2.27-.74.77-.237 1.332-.934 1.332-1.74V16.456c0-.805-.571-1.498-1.374-1.428-.892.077-1.846.308-2.526.483-.811.21-1.743.642-2.65 1.048a1.68 1.68 0 0 1-1.082.097l-6.517-1.58c-1.12-.245-2.842.162-3.9.435-.661.17-1.403.49-2.145.822-.624.279-1.029.9-1.029 1.584v14.58c0 .806.578 1.522 1.37 1.38z" />
                      <path
                        d="m27.137 33.874-6.679-1.619a1.674 1.674 0 0 0-.384-.048V15.293c0-.072.005-.144.014-.214l6.503 1.576a1.7 1.7 0 0 0 .558.04v16.977c0 .068-.004.135-.012.202z"
                        fill="#F6F6F4"
                      />
                    </g>
                  </svg>
                </div>
              </div>
            </div>
          </form>

          {props.disclaimerText && (
            <p className="text-sm text-grey-700 mb-5">{props.disclaimerText}</p>
          )}
          {mapView ? (
            <MapView
              cardColor={props.cardColor}
              iconColor={props.iconColor}
              mapColor={props.mapColor}
              mapSelectedColor={props.mapSelectedColor}
              countries={search(countries)}
              customJson={props.worldMapGeoData}
              regionLabels={props.regionLabels}
              planFormat={props.planFormat}
              showAddons={props.showAddons}
              addonFormat={props.addonFormat}
              localNumberText={props.localNumberText}
              wifiCallingText={props.wifiCallingText}
              volteText={props.volteText}
              noCoverageText={props.noCoverageText}
              noResultsText={props.noResultsText}
            />
          ) : (
            <CoverageList
              cardColor={props.cardColor}
              iconColor={props.iconColor}
              countries={search(countries)}
              regionLabels={props.regionLabels}
              planFormat={props.planFormat}
              showAddons={props.showAddons}
              addonFormat={props.addonFormat}
              localNumberText={props.localNumberText}
              wifiCallingText={props.wifiCallingText}
              volteText={props.volteText}
              noCoverageText={props.noCoverageText}
              noResultsText={props.noResultsText}
            />
          )}
        </div>
      </div>
    </>
  )
}
