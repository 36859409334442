import React from 'react'
import { LinkWrap } from '@truphone/gatsby-wrappers'

export default function FooterLinks(props) {
  return (
    <div
      className={`py-10`}
      style={{
        backgroundColor: props.backgroundColor ? props.backgroundColor : 'transparent',
        color: props.color ? props.color : '#000e35'
      }}
    >
      {props.title && <h2 className="text-2xl text-center px-6 mb-4">{props.title}</h2>}
      <div className="container mx-auto px-6">
        {props.cards && (
          <div className={`flex flex-wrap -mx-4`}>
            {props.cards.map((box, i, list) => {
              const cta = box.cta && box.cta.length > 0 && box.cta[0]
              return (
                <div
                  key={`footer-link-${i}`}
                  className={`relative w-full p-4 
                  ${list.length % 2 == 0 ? 'md:w-1/2' : list.length % 3 === 0 ? 'md:w-1/3' : ''} 
                  `}
                >
                  {cta && (
                    <LinkWrap href={cta.href} className="z-0 absolute w-full h-full"></LinkWrap>
                  )}
                  <div className="flex flex-col justify-center bg-white text-black rounded p-4 h-full">
                    {box.title && <h3 className="text-base font-semibold">{box.title}</h3>}
                    {box.content && <h3 className="text-sm">{box.content}</h3>}
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </div>
  )
}
