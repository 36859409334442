import React, { useState, useEffect } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import cx from 'classnames'
import { ClickOutside } from '@truphone/utils'

const TriangleIcon = (props) => (
  <div
    className={`${props.open ? 'rotate-180' : ''} w-0 h-0`}
    style={{
      borderStyle: 'solid',
      borderWidth: '8px 5.5px 0 5.5px',
      borderColor: '#000000 transparent transparent transparent'
    }}
  ></div>
)

function MultiSelect(props) {
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItems, setSelectedItems] = useState([])

  useEffect(() => {
    if (!props.value) return

    if (typeof props.value === 'string') {
      const selectedValues = [...selectedItems]
      const values = props.value.split(',')

      values.forEach((x) => {
        if (!selectedValues.includes(x)) {
          selectedValues.push(x)
        }
      })

      setSelectedItems(selectedValues)
    }
  }, [props.value])

  const handleSelect = (value) => {
    let arr = [...selectedItems]
    let index = selectedItems.indexOf(value)
    if (index != -1) {
      arr.splice(index, 1)
    } else {
      arr.push(value)
    }

    setSelectedItems(arr)

    if (props.onChange) {
      props.onChange(arr)
    }
  }

  return (
    <Listbox
      as="div"
      className={cx('w-full', props.className)}
      value={selectedItems}
      onChange={(value) => handleSelect(value)}
      open={isOpen}
    >
      {() => (
        <>
          <div className="relative w-full">
            <ClickOutside onClickOutside={() => setIsOpen(false)}>
              <div
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
              >
                <Listbox.Button
                  className="h-12 rounded-lg cursor-default relative z-1 w-full pl-3 pr-10 py-3 text-left  transition ease-in-out duration-150"
                  open={isOpen}
                >
                  <span className="block truncate">
                    {selectedItems.length > 0
                      ? props.options
                          .filter((x) => x.value && selectedItems.includes(x.value))
                          .map((x) => x.text)
                          .join(', ')
                      : props.placeholder}
                  </span>
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                    <TriangleIcon open={isOpen} />
                  </span>
                </Listbox.Button>
              </div>

              <div className="absolute z-30 w-full mt-1 bg-white rounded-md shadow-lg mb-11 min-w--250">
                <Transition
                  show={isOpen}
                  leave="transition duration-100 ease-in"
                  leaveFrom="transform opacity-100"
                  leaveTo="transform opacity-0"
                >
                  <Listbox.Options
                    static
                    className="py-1 overflow-auto text-base rounded-md max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  >
                    {props.options &&
                      props.options.length > 0 &&
                      props.options
                        .filter((x) => x.value)
                        .map((option, i) => {
                          const selected = selectedItems.find((x) => x === option.value)
                          return (
                            <Listbox.Option
                              as={React.Fragment}
                              key={`select-option-${option.id}-${i}`}
                              value={option.value}
                            >
                              {({ active }) => {
                                return (
                                  <li
                                    className={`${
                                      active ? 'text-black bg-gray-100' : 'text-black'
                                    } cursor-pointer select-none relative py-2 pl-3`}
                                  >
                                    <div className="flex items-center">
                                      <input
                                        checked={selected !== undefined}
                                        className="mr-3 text-blue-100 form-checkbox border-2"
                                        type="checkbox"
                                      />
                                      <span
                                        className={`${
                                          selected ? 'font-semibold' : 'font-normal'
                                        } flex items-center truncate`}
                                      >
                                        {option.text}
                                      </span>
                                    </div>
                                  </li>
                                )
                              }}
                            </Listbox.Option>
                          )
                        })}
                  </Listbox.Options>
                </Transition>
              </div>
            </ClickOutside>
          </div>
        </>
      )}
    </Listbox>
  )
}

export default MultiSelect
