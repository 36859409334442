import React, { useState, useEffect, useRef } from 'react'
import { geoMercator, geoPath } from 'd3-geo'
import './map.css'
import { Bandwidth, Cross, Plan, Tick } from './Icons'

const CountryData = (props) => {
  const content = useRef(null)
  return (
    <div
      ref={content}
      style={
        props.expanded
          ? {
              maxHeight: content.current ? content.current.scrollHeight : 1000
            }
          : { maxHeight: '0px' }
      }
      className={`overflow-hidden transition-all ${props.expanded ? 'mt-8' : ''}`}
    >
      {props.bandwidths && props.bandwidths.length > 0 ? (
        <div className="flex mb-4">
          <Bandwidth className="mr-3" style={{ color: props.iconColor }} />
          <p>
            {props.bandwidths.map((el, i) => {
              return (
                <span key={`bandwidth-${i}`}>
                  {i !== 0 ? ', ' : ''} {el}
                </span>
              )
            })}
          </p>
        </div>
      ) : (
        <div className="flex mb-4">
          <p>{props.noCoverageText}</p>
        </div>
      )}

      {props.plans && props.plans.length > 0 && (
        <div className="flex mb-4">
          <Plan className="mr-3" style={{ color: props.iconColor }} />
          <p>
            {props.plans.map((el, i) => {
              return (
                <span key={`plan-${i}`}>
                  {i !== 0 ? ', ' : ''}{' '}
                  {props.planFormat && props.planFormat.replace('{label}', el)}
                </span>
              )
            })}
          </p>
        </div>
      )}
      {props.region && props.addonFormat && (
        <div className="flex mb-4">
          <img className="mr-3 w-6" src="/images/icons/icon-add-on.svg" />
          <p>
            <span>{props.addonFormat && props.addonFormat.replace('{label}', props.region)}</span>
          </p>
        </div>
      )}

      {props.localNumber && (
        <div className="flex mb-4">
          <img className="mr-3 w-6" src="/images/icons/icon-local-number.svg" />
          <span>{props.localNumberText}</span>
        </div>
      )}

      {props.wifiCalling && (
        <div className="flex mb-4">
          <img className="mr-3 w-6" src="/images/icons/icon-wifi-calling.svg" />
          <span>{props.wifiCallingText}</span>
        </div>
      )}

      {props.voLte && (
        <div className="flex mb-4">
          <img className="mr-3 w-6" src="/images/icons/icon-wifi-calling.svg" />
          <span>{props.volteText}</span>
        </div>
      )}

      {props.disclaimer && (
        <div className="flex mb-4">
          <span>{props.disclaimer}</span>
        </div>
      )}
    </div>
  )
}

export default function MapView(props) {
  const container = useRef(null)
  const [geographies, setGeographies] = useState([])
  const [selected, setSelected] = useState('')

  useEffect(() => {
    const downloadMapData = async () => {
      const response = await fetch(props.customJson)
      if (response.ok) {
        const data = await response.json()
        setGeographies(data.features)
      }
    }

    if (props.customJson) {
      downloadMapData()
    }
  }, [props.customJson])

  const scrollToCountry = (iso) => {
    if (!iso) {
      return
    }

    const countryElement = container.current.querySelector(`#country-panel-${iso}`)
    if (countryElement) {
      const elementTop = countryElement.offsetTop
      const containerTop = container.current.offsetTop
      container.current.scrollTop = elementTop - containerTop
    }
  }

  const w = 1200
  const h = 1100

  const projection = geoMercator()
    .center([0, 15])
    .scale([w / (2.1 * Math.PI)])
    .translate([w / 2, h / 2])

  return (
    <div className="mt-10 flex flex-wrap justify-between lg:flex-nowrap container-wrap">
      <div ref={container} className="hidden lg:block h-inherit w-full lg:w-96 overflow-y-auto">
        {props.countries && props.countries.length > 0 ? (
          props.countries.map((country, i) => {
            return (
              <>
                <div
                  id={`country-panel-${country.iso}`}
                  key={`country-${i}`}
                  onClick={() => setSelected(country.iso)}
                  className={`${
                    selected !== country.iso ? 'border-white border-2' : 'border-2'
                  } cursor-pointer min-w-sm mb-4 p-2 bg-white rounded border-l-4 `}
                  style={{
                    borderLeftColor: props.cardColor,
                    borderColor: selected === country.iso && props.cardColor
                  }}
                >
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                      {country.iso && (
                        <img
                          className="flag-shadow w-11 mr-4 h-auto flex-shrink-0"
                          src={`/images/flags-stylised/${country.iso.toUpperCase()}.svg`}
                          alt={country.iso}
                        />
                      )}
                      <p className="text-xl font-semibold">{country.name}</p>
                    </div>
                    <div
                      className="h-6 flex justify-center items-center flex-shrink-0"
                      style={{ color: props.iconColor }}
                    >
                      {(!country.topupPlans && !country.activationPlans && !country.bandwidths) ||
                      (country.bandwidths && country.bandwidths.length === 0) ? (
                        <Cross />
                      ) : (
                        <Tick />
                      )}
                    </div>
                  </div>
                  <CountryData
                    iconColor={props.iconColor}
                    expanded={selected === country.iso}
                    regionLabels={props.regionLabels}
                    planFormat={props.planFormat}
                    addonFormat={props.addonFormat}
                    localNumberText={props.localNumberText}
                    wifiCallingText={props.wifiCallingText}
                    volteText={props.volteText}
                    noCoverageText={props.noCoverageText}
                    noResultsText={props.noResultsText}
                    {...country}
                  />
                </div>
              </>
            )
          })
        ) : (
          <div>{props.noResultsText}</div>
        )}
      </div>
      <div className="relative w-full overflow-auto map-wrap">
        <svg className="relative z-1 w-full h-auto map-svg" viewBox="0 0 1200 820">
          <g className="countries">
            {geographies &&
              geographies.length > 0 &&
              geographies.map((d, i) => {
                return (
                  <g key={`path-${i}`} className="relative">
                    <path
                      d={geoPath().projection(projection)(d)}
                      className={d.properties.iso_a2}
                      fill={`${
                        selected === d.properties.iso_a2
                          ? props.mapSelectedColor
                          : props.countries.find(
                              (c) =>
                                c.iso === d.properties.iso_a2 &&
                                c.bandwidths &&
                                c.bandwidths.length > 0
                            )
                          ? props.mapColor
                          : '#ebebeb'
                      }`}
                      stroke={props.mapStrokeColor}
                      strokeOpacity="0.6"
                      onClick={() => {
                        setSelected(d.properties.iso_a2)
                        scrollToCountry(d.properties.iso_a2)
                      }}
                    />
                  </g>
                )
              })}
          </g>
        </svg>
      </div>
    </div>
  )
}
