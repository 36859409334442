import React from 'react'

export const InlineDefsInline = () => {
  return (
    <svg
      style={{ position: 'absolute', width: 0, height: 0 }}
      height="0"
      width="0"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <defs>
        <symbol id="tick" width="26" height="26" viewBox="0 0 26 26">
          <g
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            fillRule="evenodd"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12z" />
            <path d="m8.2 14.2 3.49 3.6c1.644-4.72 3.011-6.79 6.11-9.6" />
          </g>
        </symbol>
        <symbol id="cross" width="26" height="26" viewBox="0 0 26 26">
          <path
            d="M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12Zm5-17L8 18m10 0L8 8"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </symbol>
        <symbol id="bandwidth" width="25" height="23">
          <path
            d="M13.665 8.92c0 .636-.521 1.15-1.165 1.15a1.158 1.158 0 0 1-1.165-1.15c0-.635.521-1.15 1.165-1.15s1.165.515 1.165 1.15zM20.772 1A10.73 10.73 0 0 1 24 8.69c0 2.905-1.146 5.636-3.227 7.69m-16.545 0A10.732 10.732 0 0 1 1 8.69 10.73 10.73 0 0 1 4.227 1m12.289 3.748c1.136 1.053 1.76 2.453 1.76 3.942 0 1.489-.624 2.888-1.76 3.942m-8.033 0c-1.135-1.054-1.76-2.453-1.76-3.942 0-1.489.624-2.889 1.76-3.941m4.017 5.322V23"
            stroke="currentColor"
            strokeWidth="2"
            fill="none"
          />
        </symbol>
        <symbol id="plan">
          <g stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd">
            <path
              d="M7.507 7.4a2.855 2.855 0 0 0-4.273.47c-.155.214-.343.462-.583.77a5.465 5.465 0 0 0 .007 6.718c1.14 1.435 2.334 2.883 3.668 4.24 1.335 1.356 2.761 2.57 4.176 3.726 1.91 1.567 4.69 1.567 6.611.01.34-.278.608-.482.834-.65 1.358-1.007 1.59-2.897.489-4.189a49.738 49.738 0 0 0-1.558-1.72c-.722-.773-1.963-.86-2.757-.16a31.76 31.76 0 0 0-.56.518c-2.196-1.324-3.528-2.694-4.809-4.884.234-.252.395-.436.518-.577a2.06 2.06 0 0 0-.162-2.79c-.527-.498-1.057-.999-1.6-1.482z"
              strokeLinejoin="round"
            />
            <path
              d="M18.693 11.31a4.868 4.868 0 0 0-1.655-2.806 4.731 4.731 0 0 0-3.154-1.108M24.5 10.335a10.983 10.983 0 0 0-3.736-6.329 10.678 10.678 0 0 0-7.125-2.503"
              strokeLinecap="round"
            />
          </g>
        </symbol>
      </defs>
    </svg>
  )
}

export const Tick = () => {
  return (
    <svg width="26" height="26">
      <use xlinkHref="#tick"></use>
    </svg>
  )
}

export const Cross = () => {
  return (
    <svg width="26" height="26" style={{ opacity: 0.4 }}>
      <use xlinkHref="#cross"></use>
    </svg>
  )
}

export const Bandwidth = ({ className, style }) => {
  return (
    <svg className={className} style={style} width="25" height="23">
      <use xlinkHref="#bandwidth"></use>
    </svg>
  )
}

export const Plan = ({ className, style }) => {
  return (
    <svg className={className} style={style} width="26" height="26">
      <use xlinkHref="#plan"></use>
    </svg>
  )
}
