import React from 'react'
import { Bandwidth, Cross, Plan, Tick } from './Icons'

export default function CoverageCard(props) {
  return (
    <div className="animation-fade w-full w-card p-4">
      <div
        className={`border-t-4 ${
          props.borderColor ? '' : 'border-blue-100'
        } bg-white p-4 shadow-coverage rounded-t-sm rounded-b-2xl h-full`}
        style={{
          borderColor: props.borderColor
        }}
      >
        <div className="flex justify-between">
          {props.iso && (
            <img
              className="w-15 h-auto flag-shadow"
              src={`/images/flags-stylised/${props.iso.toUpperCase()}.svg`}
              alt={props.iso}
            />
          )}
          <div className="h-6 flex justify-center items-center" style={{ color: props.iconColor }}>
            {!props.topupPlans && !props.activationPlans && !props.bandwidths ? (
              <Cross />
            ) : (
              <Tick />
            )}
          </div>
        </div>
        <h3 className="text-xl font-semibold border-b border-gray-200 mt-6 mb-4 pb-1">
          {props.name}
        </h3>
        {props.bandwidths && props.bandwidths.length > 0 && (
          <div className="flex items-start mb-4 text-grey-700">
            {props.bandwidths.length > 0 && (
              <Bandwidth className="mr-3 shrink-0" style={{ color: props.iconColor }} />
            )}
            <p>
              {props.bandwidths.map((el, i) => {
                return (
                  <span key={`bandwidth${i}`}>
                    {i !== 0 ? ', ' : ''} {el}
                  </span>
                )
              })}
            </p>
          </div>
        )}
        {props.plans && props.plans.length > 0 && (
          <div className="flex items-start mb-4 text-grey-700">
            <Plan className="mr-3 shrink-0" style={{ color: props.iconColor }} />
            <p>
              {props.plans.map((el, i) => {
                return (
                  <span key={`bandwidth${i}`}>
                    {i !== 0 ? ', ' : ''}{' '}
                    {props.planFormat && props.planFormat.replace('{label}', el)}
                  </span>
                )
              })}
            </p>
          </div>
        )}
        {props.region && props.addonFormat && (
          <div className="flex items-start mb-4 text-grey-700">
            <img className="mr-3 w-6 shrink-0" src="/images/icons/icon-add-on.svg" alt="" />
            <p>
              <span>{props.addonFormat && props.addonFormat.replace('{label}', props.region)}</span>
            </p>
          </div>
        )}

        {props.localNumber && (
          <div className="flex items-start mb-4 text-grey-700">
            <img className="mr-3 w-6 shrink-0" src="/images/icons/icon-local-number.svg" alt="" />
            <span>{props.localNumberText}</span>
          </div>
        )}
        {props.wifiCalling && (
          <div className="flex items-start mb-4 text-grey-700">
            <img className="mr-3 w-6 shrink-0" src="/images/icons/icon-wifi-calling.svg" alt="" />
            <span>{props.wifiCallingText}</span>
          </div>
        )}

        {props.voLte && (
          <div className="flex items-start mb-4 text-grey-700">
            <img className="mr-3 w-6 shrink-0" src="/images/icons/icon-wifi-calling.svg" alt="" />
            <span>{props.voLteText}</span>
          </div>
        )}

        {!props.topupPlans && !props.activationPlans && !props.bandwidths && (
          <div className="flex items-start mb-4 text-grey-700">
            <span>{props.noCoverageText}</span>
          </div>
        )}

        {props.disclaimer && (
          <div className="flex items-start mb-4 text-grey-700">
            <span>{props.disclaimer}</span>
          </div>
        )}
      </div>
    </div>
  )
}
