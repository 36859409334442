import React, { useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import CoverageCard from './CoverageCard'
import { Loading } from '@truphone/icons'

export default function CoverageList(props) {
  const [page, setPage] = useState(1)
  const perPage = props.perPage || 12
  const [loadmore, setLoadMore] = useState(true)
  const [showCountries, setShowCountries] = useState(props.countries.slice(0, page * perPage))

  useEffect(() => {
    let page = 1
    if (page === 1) {
      setLoadMore(true)
      setShowCountries(props.countries.slice(0, page * perPage))
    }
  }, [props.countries])

  const loadFunc = (page) => {
    if (showCountries.length < props.countries.length) {
      setPage(page)
      setLoadMore(true)
      setShowCountries(props.countries.slice(0, page * perPage))
    } else {
      setLoadMore(false)
    }
  }

  return showCountries && showCountries.length > 0 ? (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadFunc}
      hasMore={loadmore}
      threshold={400}
      loader={<Loading key={0} />}
      useWindow={true}
    >
      <div className="flex flex-wrap -mx-4">
        {showCountries.map((country) => {
          return (
            <CoverageCard
              key={country.iso}
              {...country}
              borderColor={props.cardColor}
              iconColor={props.iconColor}
              regionLabels={props.regionLabels}
              planFormat={props.planFormat}
              showAddons={props.showAddons}
              addonFormat={props.addonFormat}
              localNumberText={props.localNumberText}
              wifiCallingText={props.wifiCallingText}
              voLteText={props.volteText || 'VoLTE'}
              noCoverageText={props.noCoverageText}
            />
          )
        })}
      </div>
    </InfiniteScroll>
  ) : (
    <div className="bg-blue-900 bg-opacity-5 max-w-sm p-4 rounded-lg text-base">
      {props.noResultsText}
    </div>
  )
}
